import { useState, useEffect } from "react"
import LogoAE from "./public/img/aesir-icon.png"
import "./App.css"
import { fetchData } from "./utils"
import type { FileType } from "./types"
import SideBarItem from "./components/SideBarItem"
import Button from "./components/Button"
import Spinner from "./components/Spinner"
import ConfigModal from "./components/ConfigModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faDownload,
  faFloppyDisk,
  faGear,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons"

const LAST_CONFIG_KEY = "lastConfig"

function App() {
  const [textAreaValue, setTextAreaValue] = useState<string>("")
  const [modifiedDate, setModifiedDate] = useState<string>("")
  const [selectedFile, setSelectedFile] = useState<number>(-1)
  const [files, setFiles] = useState<FileType[]>([])
  const [isJSONValid, setIsJSONValid] = useState<boolean>(true)
  const [isModified, setIsModified] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [configModalVisible, setConfigModalVisible] = useState<boolean>(false)
  const [configText, setConfigText] = useState<string>(
    localStorage.getItem(LAST_CONFIG_KEY) || ""
  )

  function addFile(
    destinoDelFile: FileType[],

    fileName: string,
    url: string,
    label: string,

    readonly: boolean = false
  ) {
    destinoDelFile.push({
      fileName,
      url,
      label,
      readonly,
      data: {
        jsonData: {},
        headers: {},
        momentAgo: "",
        lastModified: null,
      },
    })
  }

  function clickOnFile(index: number) {
    if (index !== -1) {
      setTextAreaValue(
        JSON.stringify(files[index].data?.jsonData, undefined, 4)
      )
      setIsJSONValid(true)
    } else {
      setTextAreaValue(
        `No se seleccionó ningún archivo. ` +
          "\n" +
          `Elija el archivo que desea visualizar en el menú lateral.`
      )
    }

    setSelectedFile(index)
    setIsModified(false)
    setModifiedDate(files[index].data.momentAgo)
  }

  async function start() {
    const arrayTemporal: FileType[] = []

    addFile(
      arrayTemporal,
      "launcher-version.json",
      "https://api.aesir-online.net/dev/launcher-version.json",
      "Versión del launcher"
    )
    addFile(
      arrayTemporal,
      "version.json",
      "https://api.aesir-online.net/dev/version.json",
      "Versión",
      true
    )
    addFile(
      arrayTemporal,
      "server-list.json",
      "https://api.aesir-online.net/dev/server-list.json",
      "Lista de servidores"
    )

    for (const file of arrayTemporal) {
      file.data = await fetchData(file.url)
    }

    setFiles(arrayTemporal)
    setLoading(false)
  }

  useEffect(() => {
    start()
  }, [])

  const onTextAreaChange = (event: any) => {
    if (selectedFile === -1) return

    if (files[selectedFile].readonly) return

    const newStateText = event.target.value

    try {
      JSON.parse(newStateText)
      setIsJSONValid(true)
    } catch (err) {
      setIsJSONValid(false)
    }
    setTextAreaValue(newStateText)

    if (
      newStateText ===
      JSON.stringify(files[selectedFile].data?.jsonData, undefined, 4)
    ) {
      setIsModified(false)
    } else {
      setIsModified(true)
    }
  }

  const saveChanges = () => {
    if (selectedFile === -1) return
    files[selectedFile].data.jsonData = JSON.parse(textAreaValue)
    setIsModified(false)
  }

  const downloadFile = (index: number) => {
    const arrayTemporal: FileType[] = [...files]
    setLoading(true)
    fetchData(arrayTemporal[index].url).then((data) => {
      arrayTemporal[index].data = data
      setFiles(arrayTemporal)

      if (selectedFile === index) {
        clickOnFile(index)
      }

      setLoading(false)
    })
  }

  return (
    <div className="row-container">
      <div className="sidebar">
        <div className="sidebar-img" />
        <ul className="list">
          {files.map((file, index) => {
            let itemClassName = "sidebar-item"
            if (selectedFile === index) {
              itemClassName = "sidebar-item selected"
            }

            return (
              <li
                onClick={() => clickOnFile(index)}
                key={index}
                className={itemClassName}
              >
                <SideBarItem label={file.label} />
              </li>
            )
          })}
        </ul>
      </div>
      <div className="main">
        <div className="navbar">
          <p>
            <Button onClick={() => setConfigModalVisible(true)}>
              <FontAwesomeIcon icon={faGear}></FontAwesomeIcon>
            </Button>
          </p>
        </div>

        <div className="fileviewer">
          <div className="filebar">
            <div id="filename" className="file-name">
              Nombre del archivo:
            </div>
            <strong>
              {selectedFile !== -1 && files[selectedFile].fileName}
            </strong>

            <div id="filedate" className="file-date">
              Última vez modificado:
            </div>
            <strong> {modifiedDate} </strong>
          </div>
          <br />
          <div className="buttons-bar">
            <Button
              className="button"
              disabled={loading || selectedFile === -1}
              onClick={() => downloadFile(selectedFile)}
            >
              <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
            </Button>
            <Button
              disabled={
                loading || selectedFile === -1 || !isJSONValid || !isModified
              }
            >
              <FontAwesomeIcon icon={faUpload}></FontAwesomeIcon>
            </Button>
            <Button
              disabled={!isJSONValid || !isModified || loading}
              onClick={saveChanges}
            >
              <FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>
            </Button>
            <Button
              disabled={!isModified}
              onClick={() => clickOnFile(selectedFile)}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          </div>
          {loading ? (
            <div className="textarea">
              <Spinner />
            </div>
          ) : (
            <textarea
              className="textarea"
              placeholder="Su archivo se visualizará aquí..."
              value={textAreaValue}
              onChange={onTextAreaChange}
              style={{
                borderColor: isJSONValid ? "#9accab" : "#e47171",
              }}
            ></textarea>
          )}
        </div>
      </div>

      <ConfigModal
        visible={configModalVisible}
        hideRequest={() => setConfigModalVisible(false)}
        defaultValue={configText}
        onConfigChange={(newConfig) => {
          setConfigText(newConfig)
          localStorage.setItem(LAST_CONFIG_KEY, newConfig)
        }}
      />
    </div>
  )
}

export default App

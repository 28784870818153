import "./style.css"

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const Button = (props: ButtonProps) => {
  const customProps = {...props, className: `custom-button ${props.className || ''}`}
  return <button  {...customProps} />
}

export default Button


export function getHeaders(responseHeaders: any) {
  const entries = responseHeaders.entries()
  const ret: Record<string, string> = {}

  /**
   * entries = [
   *  ['Content-Length', '255'],
   *  ['Last-modified', '2022-01-13T14:16:40.980Z']
   * ]
   *
   * 1. Antes de la iteracion ret = {}
   *
   * 2. Desp de la primera iteracion ret = {
   *    'content-length': '255'
   * }
   *
   * 3. Desp de la segunda iteracion ret = {
   *    'content-length': '255' ,
   *    'last-modified': '2022-01-13T14:16:40.980Z'
   * }
   */

  for (const header of entries) {
    const key = header[0].toLowerCase()
    const value = header[1]
    ret[key] = value
  }

  return ret
}

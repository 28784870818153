
import { getHeaders } from "."
import { FileDataType } from "../types"

import * as moment from 'moment';
import 'moment/locale/es';

export async function fetchData(url: string): Promise<FileDataType> {
  try {
    const data = await fetch(`${url}?t=${new Date().getTime()}`)
    const headerObject = getHeaders(data.headers)
    let lastModifiedDate: Date | null = null
    if (headerObject["last-modified"]) {
      lastModifiedDate = new Date(headerObject["last-modified"])
    }

    const localeMoment = moment.default(lastModifiedDate)
    localeMoment.locale('es')
    const momentAgo = localeMoment.fromNow()
    const jsonData = await data.json()

    return {
      jsonData: jsonData,
      headers: headerObject,
      momentAgo,
      lastModified: lastModifiedDate,
    }
  } catch (err) {
    console.error(`Fetch to ${url} failed`, err)
  }
  return {
    jsonData: { status: "Falló" },
    headers: {},
    momentAgo: "",
    lastModified: null,
  }
}

import { FC } from "react"
import "./style.css"

interface ModalProps {
  visible: boolean
}

const Modal: FC<ModalProps> = function (props) {
  const { visible, children } = props

  return (
    <div className={`custom-modal ${visible ? "visible" : ""}`}>
      <div className="custom-modal-content">{children}</div>
    </div>
  )
}

export default Modal

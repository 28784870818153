import { FC, useEffect, useState } from "react"
import Button from "../Button"
import Modal from "../Modal"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCancel, faFloppyDisk } from "@fortawesome/free-solid-svg-icons"

interface ConfigModalProps {
  visible: boolean
  hideRequest: () => void
  onConfigChange?: (newConfig: string) => void
  defaultValue?: string
}

const ConfigModal: FC<ConfigModalProps> = (props) => {
  const [textAreaValue, setTextAreaValue] = useState<string>("")
  const [textWhenOpen, setTextWhenOpen] = useState<string | null>(null)

  const { visible, hideRequest, onConfigChange, defaultValue } = props
  const [isJSONValid, setIsJSONValid] = useState<boolean>(true)

  const onTextAreaChange = (event: any) => {
    const newStateText = event.target.value
    try {
      JSON.parse(newStateText)
      setIsJSONValid(true)
    } catch (err) {
      setIsJSONValid(false)
    }
    setTextAreaValue(newStateText)
  }

  function saveAndExit() {
    const data = JSON.parse(textAreaValue) as Record<string, any>
    if (onConfigChange) onConfigChange(textAreaValue)
    hideRequest()
  }

  function exit() {
    setTextAreaValue(textWhenOpen || "")
    hideRequest()
  }

  useEffect(() => {
    if (visible) {
      const nuncaSeAbrio = textWhenOpen === null
      if (nuncaSeAbrio && defaultValue) {
        setTextAreaValue(defaultValue)
        setTextWhenOpen(defaultValue)
      } else {
        setTextWhenOpen(textAreaValue)
      }

      setIsJSONValid(true)
    }
  }, [visible])

  return (
    <Modal visible={visible}>
      <div className="buttons-bar">
        <Button onClick={exit}>
          <FontAwesomeIcon icon={faCancel}></FontAwesomeIcon>
        </Button>
        <Button onClick={saveAndExit}>
          <FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>
        </Button>
      </div>

      <textarea
        className="textarea"
        placeholder="Configuración..."
        value={textAreaValue}
        onChange={onTextAreaChange}
        style={{
          borderColor: isJSONValid ? "#9accab" : "#e47171",
          minHeight: 300,
          width: "70%",
        }}
      ></textarea>
    </Modal>
  )
}

export default ConfigModal
